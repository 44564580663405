import { Box, Button, Flex, GridItem, SimpleGrid, Text } from "@chakra-ui/react"

export interface TableCardContentProps {
  label: string,
  body: string,
  button?: React.ReactNode,
}

export const TableCardContent = ({ label, body }: TableCardContentProps) => {
  return (
    <SimpleGrid  columns={{ base: 2, md: 1 }}>
      <GridItem>
        <Text
          display={{ base: 'inherit', md: 'none' }}
          as="p"
          mb="0"
          fontSize="md"
          textTransform="capitalize"
          fontWeight="bold"
        >
          {label}:
        </Text>
      </GridItem>
      <GridItem>
          {body}

      </GridItem>
    </SimpleGrid>
  )
}